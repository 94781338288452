<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12">
        <addHeader
          :title="$t('endorsements.form.addexpense')"
          :description="
            $t('endorsements.form.FromHereYouCanCreateaNewexpense')
          "
          v-if="$route.name == 'endorsementsCreate'"
        />
        <addHeader
          :title="$t('endorsements.form.expenseadjustment')"
          :description="
            $t('endorsements.form.FromHereYouCanModifyTheexpenseInformation')
          "
          v-if="$route.name == 'endorsementsEdit'"
        />
        <div class="mb-4 row">
          <formInput
            :title="$t('endorsements.form.recipientsName')"
            v-model="item.name"
            :hasErorr="errors.name"
            error="$t('endorsements.form.TheRecipientIsNameCanItBeEmpty')"
            maxlength="255"
          />
          <formInput
            :title="$t('endorsements.form.CardNumber')"
            v-model="item.identification_number"
            maxlength="255"
          />

          <div class="form-group row" :class="'col-md-6'">
            <label :class="'col-md-4'">{{ $t('endorsements.form.date') }}</label>
            <div :class="'col-md-7'">
              <div class="icon-input w-100 to-left">
                <span><i class="far fa-calendar"></i></span>

                <VueCtkDateTimePicker
                  :label="$t('endorsements.form.ChooseTimeAndDate')"
                  :button-now-translation="$t('endorsements.form.Now')"
                  v-model="item.date"
                  :disabled="
                    $user.admin ||
                    $user.role.invoices_edit_product
                      ? false
                      : true
                  "
                  format="YYYY-MM-DD hh:mm a"
                  color="#631263"
                  button-color="#631263"
                />
              </div>
            </div>
          </div>


          <div
            class="form-group row col-md-6"
            v-if="$route.name == 'endorsementsCreate'"
          >
            <label class="col-sm-4"> {{$t('endorsements.form.PrintTheDeclaration')}} </label>
            <div class="col-sm-7">
              <div class="custom-control custom-switch">
                <label for="purchase_payments_show" class="check-label"></label>
                <input
                  type="checkbox"
                  id="purchase_payments_show"
                  v-model="print"
                  class="custom-control-input"
                  value="true"
                />
                <label
                  for="purchase_payments_show"
                  class="custom-control-label"
                ></label>
              </div>
            </div>
          </div>

          <dvider
            :title="$t('endorsements.form.expenseDetails')"
            :description="$t('endorsements.form.DetailsOfTheDeclaration')"
          />

          <formTextarea :title="$t('endorsements.form.description')" v-model="item.description"/>
          <formTextarea
            :title="$t('endorsements.form.OtherData1')"
            v-model="item.data1"
          />
          <formTextarea
            :title="$t('endorsements.form.OtherData2')"
            v-model="item.data2"
          />
          <formTextarea :title="$t('endorsements.form.notes')" v-model="item.notes"/>
          <!-- Divider -->
          <hr class="mt-5 mb-5 col-12" />
          <!-- Buttons -->
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'endorsementsCreate' && !$parent.stopEdit"
          >
            {{ $t('endorsements.form.addexpense') }}
          </a>
          <a
            @click="saveItem()"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'endorsementsEdit' && !$parent.stopEdit"
          >
            {{ $t('endorsements.form.expenseadjustment') }}
          </a>
          <loading v-if="$parent.stopEdit"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

import formInput from "../elements/add/form/formInput.vue";
import formTextarea from "../elements/add/form/formTextarea.vue";
import dvider from "../elements/add/dvider.vue";
import addHeader from "../elements/add/header.vue";
import loading from "../elements/add/loading.vue";

import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

export default {
  data() {
    return {
      path: "/endorsements",
      item: {
        date: "",
      },
      model: false,
      errors: {
        name: false,
      },
      print: true,
    };
  },
  mounted() {
    if (this.$route.name == "endorsementsEdit") {
      this.$parent.stopEdit = true;
      this.getItem();
    }
    if (this.$route.name == "endorsementsCreate") {
      this.item.date = this.$nowDate()
    }
    var elm = this;
      window.addEventListener("keydown", function (e) {
        if (e.key == "F1" || e.key == "Meta") {
          e.preventDefault();
          elm.saveItem();
        }
      })
  },
  methods: {
    getItem() {
      axios
        .get(this.$linkGnirator(this.path + "/" + this.$route.params.id))
        .then((response) => {
          this.item = response.data;
          this.$parent.stopEdit = false;
        });
    },
    saveItem() {
      var error = 0;
      if (
        typeof this.item.name === "undefined" ||
        this.item.name == "" ||
        this.item.name < 1
      ) {
        error = 1;
        this.errors.name = true;
      }
      if (error == 0) {
        this.item.date = this.$fixDate(this.item.date)
        if (this.$route.name == "endorsementsCreate") {
          this.$parent.aletText = this.$t('allerts.endorsementsuccessfullyAdde');
          this.$parent.alertType = "success";
          this.$addToLocalDatabase(this.item, this.path.substring(1));
          this.$router.go(-1);
          this.$parent.stopEdit = false;
        } else if (this.$route.name == "endorsementsEdit") {
          this.item._method = "PUT";
          this.$parent.stopEdit = true;
          axios
            .post(this.$linkGnirator(this.path + "/" + this.item.id), this.item)
            .then((response) => {
              this.$parent.aletText =
                this.$t('allerts.expenseHasBeenModifiedSuccessfully');
              this.$parent.alertType = "success";
              this.$router.go(-1);
              this.$parent.stopEdit = false;
              return response;
            });
        }
      } else {
        this.$parent.aletText = this.$t('allerts.pleaseMakeSureOfTheInput');
        this.$parent.alertType = "danger";
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
  },
  components: {
    formInput,
    dvider,
    addHeader,
    formTextarea,
    loading,

    VueCtkDateTimePicker
  },
};
</script>
